import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const GoalCalculator = () => {
  const [goal, setGoal] = useState(""); // Initial goal is empty
  const [years, setYears] = useState(""); // Saving period in years
  const [lumpSum, setLumpSum] = useState(""); // Lump sum amount
  const [monthlySaving, setMonthlySaving] = useState(null);
  const [error, setError] = useState("");
  const [returnRate, setReturnRate] = useState(10.5); // Default return rate 10.5%

  // Handle goal input change and add currency symbol
  const handleGoalChange = (value) => {
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
    const numericValueParsed = parseInt(numericValue, 10);

    if (numericValueParsed >= 10000 && numericValueParsed <= 10000000000) {
      setGoal(`₹ ${numericValueParsed}`); // Format with ₹ symbol if valid
      setError(""); // Clear error if valid
    } else {
      setGoal(value); // Allow typing without symbol if invalid
      setError("Value must be between ₹10,000 and ₹10,00,00,000.");
    }
  };

  // Calculate monthly savings based on the inputs
  const calculateSavings = () => {
    const annualRate = returnRate / 100; // Annual return rate in decimal
    const monthlyRate = annualRate / 12; // Monthly return rate
    const months = years * 12; // Total number of months

    // Future value of lump sum
    const lumpSumValue = parseFloat(lumpSum) || 0; // Ensure lumpSum is a number
    const futureValueOfLumpSum = lumpSumValue * Math.pow(1 + monthlyRate, months);

    // Adjust goal after considering lump sum
    const adjustedGoal = goal.replace("₹", "").trim().replace(/,/g, ""); // Remove ₹ and commas
    const numericAdjustedGoal = parseInt(adjustedGoal, 10) - futureValueOfLumpSum;

    if (numericAdjustedGoal <= 0) {
      setMonthlySaving(0); // No monthly saving needed if lump sum is sufficient
      return;
    }

    // Calculate monthly savings using the formula for ordinary annuity
    const monthlySavingValue =
      numericAdjustedGoal / 
      (((Math.pow(1 + monthlyRate, months) - 1) / monthlyRate) * (1 + monthlyRate)); // Formula for monthly saving

    setMonthlySaving(monthlySavingValue.toFixed(2)); // Set monthly savings
  };

  // Recalculate savings whenever any of the relevant values change
  useEffect(() => {
    if (goal && years && lumpSum) {
      calculateSavings();
    }
  }, [goal, years, lumpSum, returnRate]); // Dependencies: whenever these change, recalculate

  // Data for the bar chart
  const chartData = {
    labels: Array.from({ length: 6 }, (_, i) => 5 + i * 5), // Labels from 5 to 30 with a difference of 5 years
    datasets: [
      {
        label: "Monthly Payment (₹)",
        data: Array.from({ length: 6 }, (_, i) => {
          const period = 5 + i * 5; // Saving period starting from 5 years, then increasing by 5
          const annualRate = returnRate / 100;
          const monthlyRate = annualRate / 12;
          const periodMonths = period * 12; // Total months
  
          // Future value of lump sum
          const lumpSumValue = parseFloat(lumpSum) || 0;
          const futureValueOfLumpSum = lumpSumValue * Math.pow(1 + monthlyRate, periodMonths);
  
          // Adjusted goal
          const adjustedGoal = goal.replace("₹", "").trim().replace(/,/g, "");
          const numericAdjustedGoal = parseInt(adjustedGoal, 10) - futureValueOfLumpSum;
  
          // Monthly payment calculation
          const monthlyPayment =
            numericAdjustedGoal <= 0
              ? 0
              : numericAdjustedGoal / 
                (((Math.pow(1 + monthlyRate, periodMonths) - 1) / monthlyRate) * 
                 (1 + monthlyRate));
  
          return parseFloat(monthlyPayment.toFixed(2)); // Return monthly payment value
        }),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };
  

  return (
    <>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Goal Calculator</h1>
              <span>Do you have a specific goal in mind? It could be anything: a foreign vacation, your child’s education, your dream house… anything. Do you have a certain number you’d like to have in your bank account at a certain point in time?</span>
            </div>
          </div>
        </div>
      </div>
      <div className="goal-calculator-container">
        <div className="form-section">
          <h2>How much are you aiming to save?</h2>
          <input
            type="text"
            value={goal}
            onChange={(e) => handleGoalChange(e.target.value)}
            placeholder="₹ 1,00,000"
          />
          {error && <p className="error-message">{error}</p>}

          <h2>Time (years)</h2>
          <input
            type="number"
            value={years}
            onChange={(e) => setYears(e.target.value)}
            placeholder="10"
          />

          <h2>Do you have a lump sum amount?</h2>
          <input
            type="number"
            value={lumpSum}
            onChange={(e) => setLumpSum(e.target.value)}
            placeholder="₹ 50,000"
          />

          <button onClick={calculateSavings} disabled={!!error}>
            Calculate
          </button>
        </div>

        <div className="results-section">
          {monthlySaving !== null && (
            <div className="result-summary">
              <h3>
                To meet your target, you need to save{" "}
                <span className="highlight">₹{monthlySaving}</span> per month
              </h3>
              <p>
                Your lump sum contribution:{" "}
                <span className="highlight">₹{lumpSum}</span>
              </p>
              <p>
                Total invested: ₹
                {(
                  (parseFloat(lumpSum) || 0) +
                  (parseFloat(monthlySaving) * years * 12)
                ).toFixed(2)}
              </p>
            </div>
          )}
          <div className="chart-container">
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false },
                },
                scales: {
                  x: {
                    title: { display: true, text: "Saving period (years)" },
                    ticks: { stepSize: 5 }, // Set step size to 5
                  },
                  y: {
                    title: { display: true, text: "Monthly payment (₹)" },
                  },
                },
              }}
            />
          </div>
          <h5>Annual Return Rate (%):</h5>
          <input
            type="range"
            min="1"
            max="25"
            step="0.5"
            value={returnRate}
            onChange={(e) => setReturnRate(Number(e.target.value))}
          />
          <p>Selected Rate: {returnRate}%</p>
        </div>
      </div>
      <p className="disclaimer">
        <span style={{fontWeight:"bold"}}>Disclaimer:</span> These calculations are for illustration only and do not represent actual
        returns.
      </p>
    </>
  );
};

export default GoalCalculator;
