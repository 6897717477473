import './App.css';
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Footer from './Footer'
import Header from './Header';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Services from './Services';
import SipCalculator from './SipCalculator';
import { elements } from 'chart.js';
import GoalCalculator from './GoalCalculator';
import RetirementGoalCalculator from './RetirementGoalCalculator';
import InflationCalculator from './InflationCalculator';


const routes = [
  { path: '/', element: <Home/> },
  {path: '/about',element:<About/>},
  { path: '/contact',element:<Contact/>},
  { path: '/services',element:<Services/>},
  { path:'/sip-calculator',element:<SipCalculator/>},
  { path:'/goal-calculator',element:<GoalCalculator/>},
  { path:'/retirement-calculator',element:<RetirementGoalCalculator/>},
  { path:'/inflation-calculator', element:<InflationCalculator/>}
 
]

  const App = () => {
  
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
  
    }, [location])
  
  return (
    <>
      <Header />
      <Routes>
        {routes.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
