import React from "react";

const Footer = () => {
  const handleSubmit = () => {
    // Get form values
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const message = document.getElementById("message").value.trim();

    // Validate form fields
    if (!name || !email || !message) {
      alert("Please fill all the fields!");
      return;
    }

    // Create the WhatsApp message
    const whatsappMessage = `\n*Name:* ${name}\n*Email:* ${email}\n*Message:* ${message}`;
    const phoneNumber = "+918788587234"; // WhatsApp number
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open WhatsApp
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 footer-item">
              <h4>Cozfin Financial Services</h4>
              <p>
                Cozfin Financial Services provides personalized financial planning and
                investment management solutions to help secure your financial future.
              </p>
              <ul className="social-icons">
                <li>
                  <a href="https://www.facebook.com/cozfinancials/" target="_blank" rel="nofollow">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/cozfin-financial-asset-services/">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li><a href="https://www.youtube.com/@cozfinancials21"><i className="fa fa-youtube"></i></a></li>
                <li><a href="https://x.com/i/flow/login?redirect_after_login=%2Fcpbadgujar"><i className="fa fa-twitter"></i></a></li>
              </ul>
            </div>
            <div className="col-md-3 footer-item">
              <h4>Financial Calculators</h4>
              <ul className="menu-list">
                <li><a href="/sip-calculator">SIP Calculator</a></li>
                <li><a href="/goal-calculator">Custom Goal Calculator</a></li>
                <li><a href="/retirement-calculator">Retirement Calculator</a></li>
                <li><a href="/inflation-calculator">Inflation Calculator</a></li>
              </ul>
            </div>
            <div className="col-md-3 footer-item">
              <h4>Quick Links</h4>
              <ul className="menu-list">
                <li><a href="/">Home</a></li>
                <li><a href="/about">About Us</a></li>
                <li><a href="/services">Our Services</a></li>
                <li><a href="/contact">Contact Us</a></li>
                {/* Added new links here */}
                <li><a href="https://www.sebi.gov.in/filings/mutual-funds.html" target="_blank" rel="nofollow">SID/SAI/KIM</a></li>
                <li><a href="https://www.amfiindia.com/investor-corner/knowledge-center/risks-in-mutual-funds.html" target="_blank" rel="nofollow">AMFI Risk Factors</a></li>
                <li><a href="/assets/pdf.pdf" download>Code of Conduct (PDF)</a></li>
              </ul>
            </div>
            <div className="col-md-3 footer-item last-item">
              <h4>Contact Us</h4>
              <div className="contact-form">
                <form id="contact-footer-contact" >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Full Name"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="E-Mail Address"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows="6"
                          className="form-control"
                          id="message"
                          placeholder="Your Message"
                          required
                        ></textarea>
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button type="submit" id="form-submit" className="filled-button" onClick={handleSubmit}>
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                Copyright &copy; 2024 Cozfin Financial Asset Services. - Design:{" "}
                <a href="https://www.shreedigimarketing.in" target="_blank" rel="nofollow">
                  Shree Digital Marketing Agency
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
