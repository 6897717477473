import React, { useRef, useState } from 'react';
import "slick-carousel/slick/slick.css"; // Slick Carousel styles
import "slick-carousel/slick/slick-theme.css"; // Slick Carousel theme
import Slider from 'react-slick';
import './About.css'

const About = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  const [showMore, setShowMore] = useState(false);
  const extraInfoRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleContent = () => {
    setShowMore(!showMore);

    if (!showMore) {
      if (extraInfoRef.current) {
        extraInfoRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div>
      {/* Page Heading */}
      <div className="page-heading header-text">
        <div className="container text-center">
          <h1>About Us</h1>
          <span className="d-block mx-auto" style={{ maxWidth: '700px' }}>
            With 3+ years industry experience and a commitment to excellence, you can trust us as your guide to your financial journey.
          </span>
        </div>
      </div>

      {/* More Info Section */}
      <div className="more-info about-info">
        <div className="container">
          <div className="row align-items-center">
            {/* Left Section */}
            <div className="col-md-6">
              <div className="right-content">
                <span>Welcome to COZFIN FINANCIAL ASSET SERVICES</span>
                <h2>
                  Get to know about <em>our company</em>
                </h2>
                <p>
                  Welcome to COZFIN FINANCIAL ASSET SERVICES, where financial expertise meets personalized service. As the founder and a seasoned financial professional, I'm dedicated to helping individuals and families achieve their financial objectives.
                </p>

                {!showMore && (
                  <p>
                    <span style={{ color: "#270949", fontWeight: "bold" }}>Professional Credentials:</span><br />
                    - Chandrakant Prabhakar Badgujar<br />
                    - AMFI Registered Mutual Fund Distributor (ARN:190030)........<br />
                    <span style={{ color: "#a4c639", cursor: "pointer", textDecoration: "none" }} onClick={toggleContent}>
                      Read More
                    </span>
                  </p>
                )}

                {showMore && (
                  <div>
                    <h5 style={{ color: "#270949" }}>Professional Credentials</h5>
                    <p>
                      - Chandrakant Prabhakar Badgujar<br />
                      - AMFI Registered Mutual Fund Distributor (ARN:190030)<br />
                      - Certified Financial Goal Planner<br />
                      - IRDA Registered Insurance Agent
                    </p>

                    <h5 style={{ color: "#270949" }}>Experience</h5>
                    <p>
                      With over 3 years of experience in the financial industry, I possess in-depth knowledge and expertise in mutual funds, investment planning, and wealth management.
                    </p>

                    <h5 style={{ color: "#270949" }}>Philosophy</h5>
                    <p>
                      At COZFIN FINANCIAL ASSET SERVICES, we believe that every individual deserves a personalized approach to financial planning.
                    </p>

                    <h5 style={{ color: "#270949" }}>Expertise</h5>
                    <p>
                      - Mutual Fund Investments<br />
                      - Financial Goal Assessment<br />
                      - Wealth Management<br />
                      - Investment Portfolio Management<br />
                      - Retirement Assessment<br />
                      - Tax Assessment
                    </p>

                    <span
                      style={{
                        color: "#a4c639",
                        cursor: "pointer",
                        textDecoration: "none",
                        display: "inline-block",
                        marginTop: "10px",
                      }}
                      onClick={toggleContent}
                    >
                      Read Less
                    </span>
                  </div>
                )}
              </div>
            </div>

            {/* Right Section */}
            <div className="col-md-6 text-center">
              <img
                className="img-fluid"
                src="assets/images/about-image.jpg"
                alt="About Cozfin"
                style={{ borderRadius: "10px", maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Fun Facts Section */}
      <div className="fun-facts">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="left-content">
                <h2>Empowering Your <em >Financial Growth</em></h2>
                <p>
                  At Cozfin Financial Services, We Offer Tailored Solutions To Help You Achieve 
                  Your Financial Goals. Whether It’s Investment Planning, Wealth Management, 
                  Or Securing Loans, Our Expertise Ensures You Stay Ahead.
                </p>
                <a href="/contact" className="filled-button">Get Started</a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row text-center">
                {[
                  { value: '10+', title: 'Cities Clientbase' },
                  { value: '40+', title: 'Happy Clients' },
                  { value: '4M+', title: 'AUM Managing' },
                  { value: '171K+', title: 'Live SIP Book' },
                ].map((fact, index) => (
                  <div key={index} className="col-6 mb-4">
                    <div className="count-area-content">
                      <h3 style={{color:"#a4c639",marginTop:"40px"}}>{fact.value}</h3>
                      <p>{fact.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Me Section */}
      <div className="why-choose-me py-5" style={{ backgroundColor: "#f3f4f6", marginTop:'10vh' }}>
      <div className="container">
        <div className="text-center mb-4">
        <h2 style={{ fontWeight: "bold", color: "#270949", display: "inline" }}>
  Why Choose <span style={{ color: "#335511" }}>Me</span>
</h2>

          <p style={{ fontSize: "1.1rem", color: "#555" }}>
            Here’s why clients trust me with their financial future:
          </p>
        </div>
        <div className="row">
          {/* Reason 1 */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div
              className="card h-100 border-0 shadow"
              style={{ borderRadius: "12px", backgroundColor: "#fff" }}
            >
              <div className="card-body text-center">
                <i
                 
                  style={{
                    fontSize: "2.5rem",
                    color: "#a4c639",
                    marginBottom: "15px",
                  }}
                ></i>
                <h5 style={{ fontWeight: "bold", color: "#270949" }}>
                  Personalized Service
                </h5>
                <p style={{ color: "#555", fontSize: "0.9rem" }}>
                  Tailored financial advice to meet your unique needs and goals.
                </p>
              </div>
            </div>
          </div>

          {/* Reason 2 */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div
              className="card h-100 border-0 shadow"
              style={{ borderRadius: "12px", backgroundColor: "#fff" }}
            >
              <div className="card-body text-center">
                <i
                  className="icon fas fa-award"
                  style={{
                    fontSize: "2.5rem",
                    color: "#a4c639",
                    marginBottom: "15px",
                  }}
                ></i>
                <h5 style={{ fontWeight: "bold", color: "#270949" }}>
                  Certified Expertise
                </h5>
                <p style={{ color: "#555", fontSize: "0.9rem" }}>
                  Expertise backed by certifications in financial planning and
                  wealth management.
                </p>
              </div>
            </div>
          </div>

          {/* Reason 3 */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div
              className="card h-100 border-0 shadow"
              style={{ borderRadius: "12px", backgroundColor: "#fff" }}
            >
              <div className="card-body text-center">
                <i
                  className="icon fas fa-lightbulb"
                  style={{
                    fontSize: "2.5rem",
                    color: "#a4c639",
                    marginBottom: "15px",
                  }}
                ></i>
                <h5 style={{ fontWeight: "bold", color: "#270949" }}>
                  Transparent Advice
                </h5>
                <p style={{ color: "#555", fontSize: "0.9rem" }}>
                  Unbiased recommendations focused on your best financial
                  interests.
                </p>
              </div>
            </div>
          </div>

          {/* Reason 4 */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div
              className="card h-100 border-0 shadow"
              style={{ borderRadius: "12px", backgroundColor: "#fff" }}
            >
              <div className="card-body text-center">
                <i
                 
                  style={{
                    fontSize: "2.5rem",
                    color: "#a4c639",
                    marginBottom: "15px",
                  }}
                ></i>
                <h5 style={{ fontWeight: "bold", color: "#270949" }}>
                  Diverse Options
                </h5>
                <p style={{ color: "#555", fontSize: "0.9rem" }}>
                  Access to a wide range of mutual fund and investment options.
                </p>
              </div>
            </div>
          </div>

          {/* Reason 5 */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div
              className="card h-100 border-0 shadow"
              style={{ borderRadius: "12px", backgroundColor: "#fff" }}
            >
              <div className="card-body text-center">
                <i
                  className="icon fas fa-chart-line"
                  style={{
                    fontSize: "2.5rem",
                    color: "#a4c639",
                    marginBottom: "15px",
                  }}
                ></i>
                <h5 style={{ fontWeight: "bold", color: "#270949" }}>
                  Proven Success
                </h5>
                <p style={{ color: "#555", fontSize: "0.9rem" }}>
                  Helping clients achieve their financial goals with data-driven
                  strategies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Testimonials Section */}
      <div className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>What they say <em>about us</em></h2>
                <span>Testimonials from our valued clients on Mutual Funds and Future Goals</span>
              </div>
            </div>
            <div className="col-md-12">
              <Slider {...settings}>
                {/* Testimonial Items */}
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Amit Kumar</p>
                    <span>Software Engineer</span>
                    <p>"I started investing in mutual funds a few years ago, and it's been one of the best decisions for my future. I now feel more secure about my financial goals."</p>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Neha Gupta</p>
                    <span>Teacher</span>
                    <p>"Mutual funds have helped me achieve my long-term financial goals. I now have the confidence to plan for my children's education and my own retirement."</p>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Ravi Singh</p>
                    <span>Business Owner</span>
                    <p>"After investing in mutual funds, I realized the importance of diversification. It has given me peace of mind, knowing that my future is financially secure."</p>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Sonia Verma</p>
                    <span>Doctor</span>
                    <p>"As a doctor, I wanted to plan for my future and mutual funds have been a great way to build a retirement corpus. It's easy and reliable."</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
