import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Box, Typography, TextField, Slider, Button, InputAdornment, Grid } from "@mui/material";
import "chart.js/auto";

const SipCalculator = () => {
  // State initialization
  const [monthlyInvestment, setMonthlyInvestment] = useState(3000);
  const [expectedRate, setExpectedRate] = useState(12);
  const [timePeriod, setTimePeriod] = useState(7);

  // SIP Calculation Logic
  const calculateFutureValue = (monthlyInvestment, annualRate, timeYears) => {
    const monthlyRate = annualRate / 12 / 100; // Monthly rate
    const totalMonths = timeYears * 12; // Total months
    return (
      monthlyInvestment *
      ((Math.pow(1 + monthlyRate, totalMonths) - 1) / monthlyRate) *
      (1 + monthlyRate)
    );
  };

  // Calculated Values
  const investedAmount = monthlyInvestment * 12 * timePeriod;
  const futureValue = calculateFutureValue(
    monthlyInvestment,
    expectedRate,
    timePeriod
  );
  const estimatedReturns = futureValue - investedAmount;

  // Doughnut Chart Data
  const chartData = {
    labels: ["Invested Amount", "Estimated Returns"],
    datasets: [
      {
        data: [investedAmount, estimatedReturns],
        backgroundColor: ["#4CAF50", "#FFC107"],
        hoverBackgroundColor: ["#388E3C", "#FFB300"],
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `₹${tooltipItem.raw.toLocaleString()}`,
        },
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          font: {
            size: 14,
          },
        },
      },
    },
    cutout: "70%", // Creates the inner circle
  };

  return (
    <>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>SIP Calculator</h1>
              <span>Calculate returns on your Systematic Investment Plan.</span>
            </div>
          </div>
        </div>
      </div>

      <Box
        sx={{
          p: 4,
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          SIP Calculator
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Left Section for Inputs */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box sx={{ textAlign: "left" }}>
              {/* Monthly Investment Input */}
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                <Typography>Monthly Investment</Typography>
                <TextField
                  value={monthlyInvestment}
                  onChange={(e) => setMonthlyInvestment(Math.max(1000, Math.min(50000, e.target.value)))}
                  size="small"
                  type="number"
                  sx={{ width: "150px" }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  }}
                />
              </Box>
              <Slider
                value={monthlyInvestment}
                min={1000}
                max={500000}
                step={1000}
                onChange={(e, value) => setMonthlyInvestment(value)}
                sx={{ mb: 3 }}
              />

              {/* Expected Rate of Return Input */}
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                <Typography>Expected Return Rate (p.a.)</Typography>
                <TextField
                  value={expectedRate}
                  onChange={(e) => setExpectedRate(Math.max(1, Math.min(30, e.target.value)))}
                  size="small"
                  type="number"
                  sx={{ width: "150px" }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Box>
              <Slider
                value={expectedRate}
                min={1}
                max={30}
                step={0.1}
                onChange={(e, value) => setExpectedRate(value)}
                sx={{ mb: 3 }}
              />

              {/* Time Period Input */}
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                <Typography>Time Period (Years)</Typography>
                <TextField
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(Math.max(1, Math.min(50, e.target.value)))}
                  size="small"
                  type="number"
                  sx={{ width: "150px" }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Years</InputAdornment>,
                  }}
                />
              </Box>
              <Slider
                value={timePeriod}
                min={1}
                max={50}
                step={1}
                onChange={(e, value) => setTimePeriod(value)}
                sx={{ mb: 4 }}
              />

              {/* Results Summary */}
              <Box sx={{ textAlign: "left" }}>
                <Typography>
                  <strong>Invested Amount:</strong> ₹{investedAmount.toLocaleString()}
                </Typography>
                <Typography>
                  <strong>Estimated Returns:</strong> ₹{estimatedReturns.toLocaleString()}
                </Typography>
                <Typography>
                  <strong>Total Value:</strong> ₹{futureValue.toLocaleString()}
                </Typography>

                {/* Button */}
                <a href="/contact">
                  <Button variant="contained" color="primary" sx={{ mt: 2, width: "100%" }}>
                    Invest Now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>

          {/* Right Section for Doughnut Chart */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box sx={{ position: "relative", width: "100%", height: "auto", maxWidth: "400px", marginTop: 7 }}>
              <Doughnut data={chartData} options={chartOptions} />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6">Total Value</Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: "#4CAF50" }}>
                  ₹{futureValue.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SipCalculator;
