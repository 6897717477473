import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Slider, Box, Typography, TextField, InputAdornment, Grid } from "@mui/material";

const InflationCalculator = () => {
  // State for sliders
  const [currentCost, setCurrentCost] = useState(100000); // Default ₹1,00,000
  const [inflationRate, setInflationRate] = useState(6); // Default 6%
  const [timePeriod, setTimePeriod] = useState(5); // Default 5 years

  // Calculated values
  const futureCost = Math.round(
    currentCost * Math.pow(1 + inflationRate / 100, timePeriod)
  );
  const costIncrease = futureCost - currentCost;

  // Chart data
  const data = {
    labels: ["Current Cost", "Total Inflation"],
    datasets: [
      {
        data: [currentCost, costIncrease],
        backgroundColor: ["#E0E7FF", "#4F46E5"],
        hoverBackgroundColor: ["#C7D2FE", "#3730A3"],
      },
    ],
  };

  // Update state from sliders or input
  const handleSliderChange = (setter) => (event, value) => {
    setter(value);
  };

  const handleInputChange = (setter) => (event) => {
    const value = event.target.value ? parseInt(event.target.value, 10) : 0;
    setter(value);
  };

  return (
    <>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Inflation Calculator</h1>
              <span>We are over 20 years of experience</span>
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{
          maxWidth: "1000px",
          margin: "auto",
          padding: "20px",
          border: "1px solid #E5E7EB",
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Grid container spacing={3}>
          {/* Left Section: Inputs and Sliders */}
          <Grid item xs={12} md={7}>
            <Box sx={{ width: "100%" }}>
              {/* Current Cost */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography>Value of Current Expenses</Typography>
                <TextField
                  value={currentCost}
                  onChange={handleInputChange(setCurrentCost)}
                  size="small"
                  type="number"
                  sx={{ width: "150px", marginLeft: "10px" }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    inputProps: { min: 10000, max: 500000 },
                  }}
                />
              </Box>
              <Slider
                value={currentCost}
                onChange={handleSliderChange(setCurrentCost)}
                step={1000}
                min={10000}
                max={500000}
                valueLabelDisplay="auto"
                sx={{ color: "#4CAF50" }}
              />

              {/* Inflation Rate */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography>Annual Inflation Rate (%)</Typography>
                <TextField
                  value={inflationRate}
                  onChange={handleInputChange(setInflationRate)}
                  size="small"
                  type="number"
                  sx={{
                    width: "150px",
                    marginLeft: "10px",
                    backgroundColor: "#e1fdf0bc",
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: { min: 1, max: 20 },
                  }}
                />
              </Box>
              <Slider
                value={inflationRate}
                onChange={handleSliderChange(setInflationRate)}
                step={0.1}
                min={0}
                max={20}
                valueLabelDisplay="auto"
                sx={{ color: "#4CAF50" }}
              />

              {/* Time Period */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography>Time Period (In Years)</Typography>
                <TextField
                  value={timePeriod}
                  onChange={handleInputChange(setTimePeriod)}
                  size="small"
                  type="number"
                  sx={{ width: "150px", marginLeft: "10px" }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Yr</InputAdornment>,
                    inputProps: { min: 1, max: 30 },
                  }}
                />
              </Box>
              <Slider
                value={timePeriod}
                onChange={handleSliderChange(setTimePeriod)}
                step={1}
                min={1}
                max={30}
                valueLabelDisplay="auto"
                sx={{ color: "#4CAF50" }}
              />

              {/* Calculation Details */}
              <Box sx={{ marginTop: "20px" }}>
                <Typography>
                  <strong>Future Cost:</strong> ₹{futureCost.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Right Section: Doughnut Chart */}
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box sx={{ width: "100%", maxWidth: "300px" }}>
                <Doughnut data={data} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <p className="disclaimer">
        <span style={{fontWeight:"bold"}}>Disclaimer:</span> These calculations are for illustration only and do not represent actual
        returns.
      </p>
      </Box>
    </>
  );
};

export default InflationCalculator;
