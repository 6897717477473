import React, { useState } from 'react';

const RetirementGoalCalculator = () => {
  const [inputs, setInputs] = useState({
    principal: '',
    annualAddition: '',
    yearsToGrow: '',
    growthRatePre: '',
    yearsToPayOut: '',
    growthRateRetire: '',
  });
  const [annualIncome, setAnnualIncome] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const calculateIncome = () => {
    const {
      principal,
      annualAddition,
      yearsToGrow,
      growthRatePre,
      yearsToPayOut,
      growthRateRetire,
    } = inputs;

    // Convert inputs to numerical values and percentages to decimals
    const P = parseFloat(principal) || 0;
    const Addition = parseFloat(annualAddition) || 0;
    const t = parseInt(yearsToGrow) || 0;
    const rPre = parseFloat(growthRatePre) / 100 || 0;
    const n = parseInt(yearsToPayOut) || 0;
    const rRetire = parseFloat(growthRateRetire) / 100 || 0;

    // Step 1: Calculate Future Value at Retirement
    const futureValue =
      P * Math.pow(1 + rPre, t) + // Growth of initial principal
      (Addition * (Math.pow(1 + rPre, t) - 1)) / rPre; // Growth of annual additions

    // Step 2: Calculate Annual Retirement Income
    let payout = 0;
    if (rRetire > 0) {
      // If growth rate is positive
      payout =
        (futureValue * rRetire) / (1 - Math.pow(1 + rRetire, -n));
    } else {
      // If growth rate is 0, divide by payout years
      payout = futureValue / n;
    }

    setAnnualIncome(payout.toFixed(2)); // Update state with result
  };

  return (
<>
<div className="page-heading header-text">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Our Services</h1>
          <span>We are over 20 years of experience</span>
        </div>
      </div>
    </div>
  </div>
    <div className="calculator-container">
      <h2>Annual Retirement Income Calculator</h2>
      <div className="form-group">
        <label>Current Principal</label>
        <input
          type="number"
          name="principal"
          value={inputs.principal}
          onChange={handleChange}
          placeholder="₹"
        />
      </div>

      <h3>Pre-Retirement</h3>
      <div className="form-group">
        <label>Annual Addition</label>
        <input
          type="number"
          name="annualAddition"
          value={inputs.annualAddition}
          onChange={handleChange}
          placeholder="₹"
        />
      </div>
      <div className="form-group">
        <label>Years to grow</label>
        <input
          type="number"
          name="yearsToGrow"
          value={inputs.yearsToGrow}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Growth Rate (%)</label>
        <input
          type="number"
          name="growthRatePre"
          value={inputs.growthRatePre}
          onChange={handleChange}
          placeholder="%"
        />
      </div>

      <h3>In Retirement</h3>
      <div className="form-group">
        <label>Years to pay out</label>
        <input
          type="number"
          name="yearsToPayOut"
          value={inputs.yearsToPayOut}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Growth Rate (%)</label>
        <input
          type="number"
          name="growthRateRetire"
          value={inputs.growthRateRetire}
          onChange={handleChange}
          placeholder="%"
        />
      </div>

      <button className="calculate-btn" onClick={calculateIncome}>
        Calculate
      </button>

      <div className="result">
        <h4>Annual Retirement Income: ₹{annualIncome}</h4>
      </div>

      <p className="disclaimer">
      <span style={{fontWeight:"bold"}}>Disclaimer:</span> These calculations are for illustration only and do not represent actual
        returns.
      </p>
    </div>
</>
  
  );
};

export default RetirementGoalCalculator;
