import React from 'react'

const Contact = () => {
  const handleSubmit = () => {

    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const subject = document.getElementById("subject").value.trim();
    const message = document.getElementById("message").value.trim();

    // Validate form fields
    if (!name || !email || !subject || !message) {
        alert("Please fill all the fields!");
        return;
    }

    // Create the WhatsApp message
    const whatsappMessage = `\n*Name:* ${name}\n*Email:* ${email}\n*Subject:* ${subject}\n*Message:* ${message}`;

    // WhatsApp API URL
    const phoneNumber = "+918788587234"; // WhatsApp number in international format
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");
  }

  return (

        <div>
          <div className="page-heading header-text">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1>Contact Us</h1>
                  <span>Feel free to send us a message now!</span>
                </div>
              </div>
            </div>
          </div>
    
          <div className="contact-information">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="contact-item">
          <i className="fa fa-phone"></i>
          <h4>Phone</h4>
          <p>
            Reach out to us for personalized financial advice and assistance. 
            Our team is ready to help you with your financial planning needs.
          </p>
          <a href="tel:8788587234">+91 8788587234</a>
        </div>
      </div>
      <div className="col-md-4">
        <div className="contact-item">
          <i className="fa fa-envelope"></i>
          <h4>Email</h4>
          <p>
            For inquiries, partnership opportunities, or any other assistance, 
            drop us an email. We'll get back to you as soon as possible.
          </p>
          <a href="mailto:cozfinancials@gmail.com">cozfinancials@gmail.com</a>
        </div>
      </div>
      <div className="col-md-4">
        <div className="contact-item">
          <i className="fa fa-map-marker"></i>
          <h4>Location</h4>
          <p>
            122-b Shanti Niketan Society, behind Transport Nagar, MIDC, 
            Jalgaon, Maharashtra 425003
          </p>
          <a
            href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7449.3130328699535!2d75.58992382229002!3d21.006401470890342!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f9aae10d7f9%3A0xf4bb61901a5ee6c9!2sCOZFIN%20FINANCIAL%20ASSET%20SERVICES%20Mutual%20fund%20distributor%20Jalgaon!5e0!3m2!1sen!2sin!4v1732097686549!5m2!1sen!2sin"
            target="_blank"
            rel="noopener noreferrer"
          >
            View on Google Maps
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

    
          <div className="callback-form contact-us">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading">
                    <h2>Send us a <em>message</em></h2>
                    <span>Feel free to send us a message now!</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-form">
                    <form id="contact" >
                      <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <fieldset>
                            <input name="name" type="text" className="form-control" id="name" placeholder="Full Name" required />
                          </fieldset>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <fieldset>
                            <input
                              name="email"
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="E-Mail Address"
                              required
                            />
                          </fieldset>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <fieldset>
                            <input name="subject" type="text" className="form-control" id="subject" placeholder="Subject" required />
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <textarea
                              name="message"
                              rows="6"
                              className="form-control"
                              id="message"
                              placeholder="Your Message"
                              required
                            ></textarea>
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <button type="submit" id="form-submit" className="filled-button" onClick={handleSubmit} >
                              Send Message
                            </button>
                          </fieldset>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <div id="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7449.3130328699535!2d75.58992382229002!3d21.006401470890342!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f9aae10d7f9%3A0xf4bb61901a5ee6c9!2sCOZFIN%20FINANCIAL%20ASSET%20SERVICES%20Mutual%20fund%20distributor%20Jalgaon!5e0!3m2!1sen!2sin!4v1732097686549!5m2!1sen!2sin"
          title='map' width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
    
          
        </div>
    
  )
}

export default Contact