import React from 'react';

const Services = () => {

  const handleSubmit = () => {

    // Get form values
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const subject = document.getElementById("subject").value.trim();
    const message = document.getElementById("message").value.trim();

    // Validate form fields
    if (!name || !email || !subject || !message) {
        alert("Please fill all the fields!");
        return;
    }

    // Create the WhatsApp message
    const whatsappMessage = `\n*Name:* ${name}\n*Email:* ${email}\n*Subject:* ${subject}\n*Message:* ${message}`;

    // WhatsApp API URL
    const phoneNumber = "+918788587234"; // WhatsApp number in international format
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");
  }

  return (
    <div>
      {/* Page Heading Section */}
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Our Services</h1>
              <span>Empowering Financial Freedom , One Goal At A Time</span>
            </div>
          </div>
        </div>
      </div>

      <div className="services">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-heading">
          <h2>Financial <em>Products</em></h2>
          <span>Your trusted partner for personalized financial solutions</span>
        </div>
      </div>

      <div className="col-md-4 mb-4"> {/* Added Bootstrap spacing utility class */}
        <div className="service-item" >
          <img src="assets/images/Mutual-Fund.jpg" alt="Tax Assessment" />
          <div className="down-content">
            <h4>Mutual Fund</h4>
            <p>A mutual fund pools money from multiple investors and invests in stocks, bonds, or other securities. It is managed by professional fund managers.</p>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/NPS.jpg" alt="Retirement Goal Assessment" />
          <div className="down-content">
            <h4>NPS(National Pension Scheme)</h4>
            <p>A government-backed retirement savings scheme that provides regular income after retirement.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/Bonds.jpg" alt="Custom Goal Assessment" style={{objectFit:'cover'}}/>
          <div className="down-content">
            <h4>Bonds</h4>
            <p>A bond is a fixed-income investment where you lend money to entities (government or corporations) in exchange for periodic interest and repayment of the principal amount at maturity.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/Life-Insurance.jpg" alt="Child Financial Future Assessment" />
          <div className="down-content">
            <h4>Life Insurance</h4>
            <p>A contract between an individual and an insurer, ensuring financial compensation to the beneficiary in case of the insured's death.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item">
          <img src="assets/images/general-insurance.png" alt="Adequate Insurance Assessment" />
          <div className="down-content">
            <h4>General Insurancet</h4>
            <p>A type of insurance that covers assets and liabilities, such as health, motor, home, and travel insurance.Protects against financial losses due to accidents, theft, or natural calamities.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/fixed-deposit.jpg" alt="Portfolio Review and Assessment" />
          <div className="down-content">
            <h4>Fixed Deposit</h4>
            <p>A fixed deposit (FD) is a financial instrument where you deposit money for a fixed tenure at a predetermined interest rate.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/Loan-Against-Mutual-Fund.jpg" alt="Net Worth Review and Assessment"/>
          <div className="down-content">
            <h4>Loan Against Mutual Fund</h4>
            <p>A facility that allows investors to borrow money by pledging their mutual fund units as collateral.Quick and easy access to funds without liquidating investments.</p>
           
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div className="services">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-heading">
          <h2>Financial <em>Services</em></h2>
          <span>Your trusted partner for personalized financial solutions</span>
        </div>
      </div>

      <div className="col-md-4 mb-4"> {/* Added Bootstrap spacing utility class */}
        <div className="service-item" >
          <img src="assets/images/service_01_11zon.jpg" alt="Tax Assessment" />
          <div className="down-content">
            <h4>Tax Assessment</h4>
            <p>We provide expert guidance to help you navigate tax regulations and ensure accurate and efficient tax filings.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_02_11zon.jpg" alt="Retirement Goal Assessment" />
          <div className="down-content">
            <h4>Retirement Goal Assessment</h4>
            <p>Plan for a comfortable retirement with our customized retirement goal assessment services.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_03_11zon.jpg" alt="Custom Goal Assessment" style={{objectFit:'cover'}}/>
          <div className="down-content">
            <h4>Custom Goal Assessment</h4>
            <p>Achieve your unique financial objectives with our personalized custom goal assessment services.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_04_11zon.jpg" alt="Child Financial Future Assessment" />
          <div className="down-content">
            <h4>Child Financial Future Assessment</h4>
            <p>Secure your child’s financial future with strategic planning and expert advice.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item">
          <img src="assets/images/service_05_11zon.jpg" alt="Adequate Insurance Assessment" />
          <div className="down-content">
            <h4>Adequate Insurance Assessment</h4>
            <p>Ensure comprehensive coverage with our insurance assessment tailored to your needs.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_06_11zon.jpg" alt="Portfolio Review and Assessment" />
          <div className="down-content">
            <h4>Portfolio Review & Assessment</h4>
            <p>Maximize returns and minimize risks with our professional portfolio review services.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_07_11zon.jpg" alt="Net Worth Review and Assessment"/>
          <div className="down-content">
            <h4>Net Worth Review & Assessment</h4>
            <p>Understand your financial health with our comprehensive net worth review services.</p>
           
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_08_11zon.jpg" alt="Legacy Building Assessment" />
          <div className="down-content">
            <h4>Legacy Building Assessment</h4>
            <p>Preserve your wealth for future generations with our legacy building assessment services.</p>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



      {/* Callback Form Section */}
      <div className="callback-form callback-services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>Request a <em>call back</em></h2>
                <span>Feel free to send us a message now!</span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-form">
              <form id="contact" >
  <div className="row">
    <div className="col-lg-4 col-md-12 col-sm-12">
      <fieldset>
        <input name="name" type="text" className="form-control" id="name" placeholder="Full Name" required=""/>
      </fieldset>
    </div>
    <div className="col-lg-4 col-md-12 col-sm-12">
      <fieldset>
        <input name="email" type="email" className="form-control" id="email" placeholder="E-Mail Address" required=""/>
      </fieldset>
    </div>
    <div className="col-lg-4 col-md-12 col-sm-12">
      <fieldset>
        <input name="subject" type="text" className="form-control" id="subject" placeholder="Subject" required=""/>
      </fieldset>
    </div>
    <div className="col-lg-12">
      <fieldset>
        <textarea name="message" rows="6" className="form-control" id="message" placeholder="Your Message" required=""></textarea>
      </fieldset>
    </div>
    <div className="col-lg-12">
      <fieldset>
        <button type="submit" id="form-submit" className="border-button" onClick={handleSubmit}>Send Message</button>
      </fieldset>
    </div>
  </div>
</form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Partners Section */}
     
      
    </div>
  );
};


export default Services;
